import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import PropertyNoResult from "../../../Components/property-noresult/property-noresult"
import {
  Popover,
  OverlayTrigger,
  Card,
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import _ from "lodash"
import { Link, PropertyCallTo } from "@StarberryUtils"
import Select from "react-select"
import $ from "jquery"
import qs from "qs"
import PropTypes from "prop-types"
import algoliasearch from "algoliasearch/lite"
import ReactTooltip from "react-tooltip"
import MarkerMobile from "../../../Components/marker"
import {
  connectStats,
  SortBy,
  connectToggleRefinement,
  connectInfiniteHits,
  InstantSearch,
  RefinementList,
  connectSearchBox,
  Menu,
  connectMenu,
  connectRange,
  Configure,
  connectSortBy,
  ToggleRefinement,
} from "react-instantsearch-dom"
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
  CustomMarker,
} from "react-instantsearch-dom-maps"

import Cookies from "universal-cookie"
import { navigate } from "@reach/router"
import { isMobile, browserName, isBrowser } from "react-device-detect"
import Header from "../../../Components/Header/Header"
import Footer from "../../../Components/Footer/Footer"
import MapResultsDesktop from "../../../Components/Search/mapresults/mapview-desktop"
import { ShowProcessedImage } from "../../../Components/Common/ggfx-client/module/components/show-image"
import imageConfig from "../../../../static/images/config.json"
// import SoldFilter from "../../../Components/Search/soldFilter"
import {
  parseSearchUrl,
  propertyH1,
} from "../../../Components/PropertySearch/utils"
import SeoResults from "../../../Components/PropertySearch/seoResults"
import "../assets/styles/_index.scss"
// import LocationPath from "../../../Components/PropertyDetails/locationPath"

import PropertyListImg1Desktop from "../../../images/Search/propertysearchdesk1.jpg"
import PropertyListImg1Tab from "../../../images/Search/propertysearchdesk1.jpg"
import PropertyListImg1Mob from "../../../images/Search/propertysearchdesk1.jpg"
import EnquiryImgDesktop1 from "../../../images/Search/enquiry-img1.png"
import EnquiryImgTab1 from "../../../images/Search/enquiry-img1.png"
import EnquiryImgMob1 from "../../../images/Search/enquiry-img1.png"
import SearchBottomImgDesktop from "../../../images/Search/search-bottom-desktop.jpg"
import SearchBottomImgTablet from "../../../images/Search/search-bottom-tablet.jpg"
import VendorsSell from "../../../images/Search/Vendors-Sell.jpg"
import TransportList from "../../../Components/map/loc-transport-list"
import GetOffice from "../../../Components/getoffice"
import GetImageCount from "../../../Components/getpropertyimagecount"
import GetVideo from "../../../Components/getVideo"
const mycookies = new Cookies()
const updateAfter = 500
// var includeSold = true
var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""
var typefilter = null;

const searchClient = algoliasearch(
  `${process.env.GATSBY_ALGOLIA_APP_ID}`,
  `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
)

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
export const getPriceStr = price => {
  let priceStr = ""
  if (price.min && price.max) {
    priceStr =
      " between " +
      `£` +
      addCommas(price.min) +
      " and " +
      `£` +
      addCommas(price.max)
  } else if (price.max) {
    priceStr = " under " + `£` + addCommas(price.max)
  } else if (price.min) {
    priceStr = " over " + `£` + addCommas(price.min)
  }
  return priceStr
}
function addCommas(num) {
  // console.log('num : ' + num);
  var str = num.toString().split(".")
  // console.log('str, ' + str);
  if (str[0].length >= 4) {
    // add comma every 3 digits before decimal
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join(".")
}
//property description
let text_truncate = function (str, length, ending) {
  if (length == null) {
    length = 150
  }
  if (ending == null) {
    ending = "..."
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

// Property results loop
const InfiniteHits = ({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
  location,
}) => (
  <>
    <>
      {hits.length > 0 && hasPrevious && (
        <button
          id="myprevbut"
          onClick={refinePrevious}
          disabled={!hasPrevious}
          className="d-none"
        >
          Show prev
        </button>
      )}
    </>

    {hits.map((hit, i) => {
      // property details url structure
      let uriStr = ""

      if (hit.searchType === "sales") {
        uriStr = `property-for-sale/`
      }
      // property details url structure

      return (
        <>
          <div
            className="mbot col-xl-6 mysingleproperty property-list-card"
            key={i}
            id={`mySaleProp${hit.objectID}`}
            data-objectid={hit.objectID}
            data-lat={hit?._geoloc?.lat}
            data-lng={hit?._geoloc?.lng}
            onClick={() => {
              mycookies.set("mySaleProp", hit.objectID, {
                path: "/",
                maxAge: 300,
              })
              sessionStorage.setItem("searchindex", hit.objectID)
            }}
            state={{ referrer: location.pathname }}
          >
            <ScrollAnimation
              duration={0.5}
              animateIn="fadeInUp"
              offset={0}
              animateOnce={true}
              className="property-results-card"
            >
              <div
                className="property-details"
                id={`map_marker_${hit.objectID}`}
              >
                <div className="property-image-block">
                  <Link
                    onClick={() => {
                      mycookies.set("mySaleProp", hit.objectID, {
                        path: "/",
                        maxAge: 300,
                      })
                      sessionStorage.setItem("searchindex", hit.objectID)
                    }}
                    to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                  >
                    {hit.images && hit.images?.length > 0 ?
                      <picture>
                        <ShowProcessedImage
                          images={hit.images && hit.images[0]}
                          attr={{
                            className: "propery-img",
                            alt: `${hit.title} - Location Location`,
                          }}
                          transforms={imageConfig.property.searchResults.sizes}
                        />
                      </picture>
                      :
                      <div className="noImageProperty">
                        <p class="text"><i className="icon-noimage"></i> image coming soon</p>
                        </div>
                    }
                  </Link>

                  <GetImageCount id={hit.objectID} />
                    {hit.virtual_tour && hit.virtual_tour[0] && 
                       <GetVideo videoUrl={hit.virtual_tour} title={hit.title} />
                    }
               

                  {hit.status.trim().toLowerCase().replace(/ /g, "_") == "under_offer" ? (
                    <span className="category-tag">Under Offer</span>
                  ) : (
                    ""
                  )}
                  {hit.status.trim().toLowerCase().replace(/ /g, "_") == "coa_pending" ? (
                    <span className="category-tag">COA Pending</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="property-content-block">
                {/* <h2> */}
                <Link
                  onClick={() => {
                    mycookies.set("mySaleProp", hit.objectID, {
                      path: "/",
                      maxAge: 300,
                    })
                    sessionStorage.setItem("searchindex", hit.objectID)
                  }}
                  to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                  className="btn-text-link"
                >
                  <h2> {hit.display_address}</h2>

                  {/* </h2> */}

                  {hit.price ? (
                    <p className="amount">
                      &pound;{hit.price.toLocaleString()}
                    </p>
                  ) : (
                    ""
                  )}
                  {hit.title ? (
                    <p className="small-desc">{hit.title.replace(/-/g, " ")}</p>
                  ) : (
                    ""
                  )}
                  {hit.description ? (  
                      <div
                          className="large-desc" 
                          dangerouslySetInnerHTML={{
                            __html:
                            hit.description.slice(0, 150) +
                              "... ",
                          }}
                        /> 
                  ) : (
                    ""
                  )}
                  <div className="search-list-content-new">
                    <ul>
                      {hit.bedroom > 0 && (
                        <li className="bedroom">
                          <i className="icon-bed"></i>
                          <p>
                            {hit.bedroom > 1
                              ? hit.bedroom + " Bedrooms"
                              : hit.bedroom + " Bedroom"}
                          </p>
                        </li>
                      )}
                      {hit.bathroom > 0 && (
                        <li className="Bathrooms">
                          <i className="icon-bathroom"></i>
                          <p>
                            {hit.bathroom > 1
                              ? hit.bathroom + " Bathrooms"
                              : hit.bathroom + " Bathroom"}
                          </p>
                        </li>
                      )}

                      {hit.reception > 0 && (
                        <li className="reception">
                          <i className="icon-reception"></i>
                          <p>
                            {hit.reception > 1
                              ? hit.reception + " Receptions"
                              : hit.reception + " Reception"}
                          </p>
                        </li>
                      )}

                      {/* {hit.garden > 0 && (
                    <li className="garden">
                      <i className="icon-garden"></i>
                      <p>{hit.garden > 1
                                  ? hit.garden + " Private Gardens"
                                  : hit.garden + " Private Garden"}
                                  </p>
                    </li>
                    )} */}
                      {/* <li className="chainfree">
              <i className="icon-chainfree"></i>
              <p>Chain Free</p>
            </li> */}
                    </ul>
                  </div>
                  {/* <ul className="desc-list">
                    <TransportList
                      id={hit.objectID}
                      latitude={parseFloat(hit._geoloc.lat)}
                      longitude={parseFloat(hit._geoloc.lng)}
                    />
                  </ul> */}
                  <div className="list-enquiry">
                    {/* <GetOffice phone={hit.office_details && hit.office_details.telephone}/> */}

                    <p className="enquiry-text">
                      <span>
                        <Link
                          className="border-bottom-yellow"
                          key={i}
                          id={`mySaleProp${hit.objectID}`}
                          data-objectid={hit.objectID}
                          data-lat={hit?._geoloc?.lat}
                          data-lng={hit?._geoloc?.lng}
                          onClick={() => {
                            mycookies.set("mySaleProp", hit.objectID, {
                              path: "/",
                              maxAge: 300,
                            })
                            sessionStorage.setItem("searchindex", hit.objectID)
                          }}
                          to={`/${uriStr}${hit.slug}-${hit.objectID}/?id=booking`}
                          state={{ referrer: location.pathname }}
                        >
                          Request a Viewing
                        </Link>{" "}
                        {PropertyCallTo(hit.office_crm_id) &&
                        <>
                          or call{" "}
                          <a
                            className="border-bottom-yellow-slide"
                            href={`tel: ${PropertyCallTo(hit.office_crm_id)}`}
                          >
                            {PropertyCallTo(hit.office_crm_id)}
                          </a>
                        </>
                        }
                      
                      </span>
                    </p>
                  </div>
                </Link>
              </div>
            </ScrollAnimation>
          </div>
        </>
      )
    })}

    <Col lg={12} className="mb-4">
      {hits.length > 0 && hasMore && (
        <div className="d-flex justify-content-center">
          <button
            id="mynextbut"
            onClick={refineNext}
            disabled={!hasMore}
            className="btn btn-primary"
          >
            Load More
          </button>
        </div>
      )}
    </Col>
  </>
)

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)
// Property results loop

// Include sold and let
// const ToggletttRefinement = ({
//   currentRefinement,
//   label,
//   count,
//   refine,
//   createURL,
// }) => {
//   return (
//     <a
//       href={"#"}
//       onClick={event => {
//         event.preventDefault()
//         includeSold = !includeSold
//         // refine(!currentRefinement)
//       }}
//     >
//       <div className="switch-button ">
//         <label for="toggle-button">
//           <input
//             type="checkbox"
//             id="toggle-button"
//             checked={includeSold ? "checked" : ""}
//           />
//           <span className="toggle-switch">
//             <span className="toggle-switch-bar"></span>
//           </span>
//           <span className="toggle-text">{label}</span>
//         </label>
//       </div>
//     </a>
//   )
// }

// const CustomToggleRefinement = connectToggleRefinement(ToggletttRefinement)
// Include sold and let

// Property No Results
const NoStats = ({
  processingTimeMS,
  nbHits,
  location,
  status,
  department,
}) => {
  return (
    <>
      {nbHits === 0 && (
        <>
          <Col lg={12} className="text-center mb-5 no-results-text">
            <div>
              Unfortunately, we do not currently have any properties that match
              your search criteria.
            </div>
            {/* <PropertyNoResult uriStr={location} status={status} department={department}/> */}
          </Col>

          <PropertyNoResult
            uriStr={location}
            status={status}
            department={department}
          />
        </>
      )}
    </>
  )
}

const CustomNoStats = connectStats(NoStats)
// Property No Results

// Property Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Property Results count

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  return (
    <div className="dropdown-exp d-lg-block">
      <Form.Group>
        <Form.Control
          type="text"
          className="postcode_input"
          placeholder="Street, area or postcode"
          onChange={event =>
            refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())
          }
          value={currentRefinement.replace(/-/g, " ")}
        />
      </Form.Group>
    </div>
  )
})
// Searchbox

// Dropdown react select styles
const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#FDCC44" : null,
      color: isFocused ? "#ffffff" : "#000000",
    }
  },
  control: styles => ({
    ...styles,
    backgroundColor: null,
    border: 0,
    paddingLeft: 0,
    outline: 0,
    boxShadow: "none",
    color: "#ffffff",
    fontSize: "1rem",
  }),
  valueContainer: styles => ({
    ...styles,
    fontSize: "1rem",
    paddingLeft: 0,
    lineHeight: "30px",
    cursor: "pointer",
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#ffffff",
  }),
  indicatorsContainer: styles => ({
    ...styles,
    color: "#ffffff",
    cursor: "pointer",
  }),
  indicatorSeparator: () => null,
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#ffffff",
      marginLeft: 0,
    }
  },
}

const DownIndicator = () => (
  <>
    <i className={"icon-plus-yellow filter-icons"}></i>
  </>
)
// Dropdown react select styles

// Dropdown react select styles
const styles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#FDCC44" : null,
      color: isFocused ? "#ffffff" : "#555965",
    }
  },
  control: styles => ({
    ...styles,
    backgroundColor: null,
    border: 0,
    outline: 0,
    boxShadow: "none",
    color: "#555965",
    fontSize: "0.875rem",
    padding: "10",
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#555965",
    }
  },
  valueContainer: styles => ({
    ...styles,
    fontSize: "0.875rem",
    padding: 10,
  }),
  indicatorSeparator: () => null,
}
// Dropdown react select styles

// Property Min Price
const priceminRange = [
  {
    value: "0",
    label: "No Min",
  },
  {
    value: "200000",
    label: "£200,000",
  },
  {
    value: "225000",
    label: "£225,000",
  },
  {
    value: "250000",
    label: "£250,000",
  },
  {
    value: "275000",
    label: "£275,000",
  },
  {
    value: "300000",
    label: "£300,000",
  },
  {
    value: "325000",
    label: "£325,000",
  },
  {
    value: "350000",
    label: "£350,000",
  },
  {
    value: "375000",
    label: "£375,000",
  },
  {
    value: "400000",
    label: "£400,000",
  },
  {
    value: "425000",
    label: "£425,000",
  },
  {
    value: "450000",
    label: "£450,000",
  },
  {
    value: "475000",
    label: "£475,000",
  },
  {
    value: "500000",
    label: "£500,000",
  },
  {
    value: "525000",
    label: "£525,000",
  },
  {
    value: "550000",
    label: "£550,000",
  },
  {
    value: "575000",
    label: "£575,000",
  },
  {
    value: "600000",
    label: "£600,000",
  },
  {
    value: "625000",
    label: "£625,000",
  },
  {
    value: "650000",
    label: "£650,000",
  },
  {
    value: "675000",
    label: "£675,000",
  },
  {
    value: "700000",
    label: "£700,000",
  },
  {
    value: "725000",
    label: "£725,000",
  },
  {
    value: "750000",
    label: "£750,000",
  },
  {
    value: "775000",
    label: "£775,000",
  },
  {
    value: "800000",
    label: "£800,000",
  },
  {
    value: "825000",
    label: "£825,000",
  },
  {
    value: "850000",
    label: "£850,000",
  },
  {
    value: "875000",
    label: "£875,000",
  },
  {
    value: "900000",
    label: "£900,000",
  },
  {
    value: "1000000",
    label: "£1,000,000",
  },
  {
    value: "1100000",
    label: "£1,100,000",
  },
  {
    value: "1200000",
    label: "£1,200,000",
  },
  {
    value: "1300000",
    label: "£1,300,000",
  },
  {
    value: "1400000",
    label: "£1,400,000",
  },
  {
    value: "1500000",
    label: "£1,500,000",
  },
  {
    value: "1600000",
    label: "£1,600,000",
  },
  {
    value: "1700000",
    label: "£1,700,000",
  },
  {
    value: "1800000",
    label: "£1,800,000",
  },
  {
    value: "1900000",
    label: "£1,900,000",
  },
  {
    value: "2000000",
    label: "£2,000,000",
  },
  {
    value: "2250000",
    label: "£2,250,000",
  },
  {
    value: "2500000",
    label: "£2,500,000",
  },
  {
    value: "2750000",
    label: "£2,750,000",
  },
  {
    value: "3000000",
    label: "£3,000,000",
  },
  {
    value: "3250000",
    label: "£3,250,000",
  },
  {
    value: "3500000",
    label: "£3,500,000",
  },
  {
    value: "3750000",
    label: "£3,750,000",
  },
  {
    value: "4000000",
    label: "£4,000,000",
  },
  {
    value: "4250000",
    label: "£4,250,000",
  },
  {
    value: "4500000",
    label: "£4,500,000",
  },
]

const minpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let minPriceRange = priceminRange
  if (currentRefinement.max !== undefined) {
    minPriceRange = priceminRange.filter(
      x => parseInt(x.value) < parseInt(currentRefinement.max)
    )
  }
  return (
    <Form.Group>
      <div className="select-box select-box-custom select-pricerange">
        <Select
          name="minprice"
          options={minPriceRange}
          defaultValue={currentRefinement.min || ""}
          placeholder="Min Price"
          classNamePrefix={"select-opt"}
          onChange={e => {
            minpriceval = e.value
            if (e.value === "") {
              if (maxpriceval) {
                refine({
                  max: maxpriceval,
                })
              } else refine(e.value, e.label)
            } else {
              refine({
                min: e.value,
                max: maxpriceval,
              })
            }
          }}
          isSearchable={false}
          styles={styles}
        />
      </div>
    </Form.Group>
  )
}

const CustomminpriceSelectbox = connectRange(minpriceSelectbox)
// Min price

// Property Max Price
const pricemaxRange = [
  {
    value: "0",
    label: "No Max",
  },
  {
    value: "200000",
    label: "£200,000",
  },
  {
    value: "225000",
    label: "£225,000",
  },
  {
    value: "250000",
    label: "£250,000",
  },
  {
    value: "275000",
    label: "£275,000",
  },
  {
    value: "300000",
    label: "£300,000",
  },
  {
    value: "325000",
    label: "£325,000",
  },
  {
    value: "350000",
    label: "£350,000",
  },
  {
    value: "375000",
    label: "£375,000",
  },
  {
    value: "400000",
    label: "£400,000",
  },
  {
    value: "425000",
    label: "£425,000",
  },
  {
    value: "450000",
    label: "£450,000",
  },
  {
    value: "475000",
    label: "£475,000",
  },
  {
    value: "500000",
    label: "£500,000",
  },
  {
    value: "525000",
    label: "£525,000",
  },
  {
    value: "550000",
    label: "£550,000",
  },
  {
    value: "575000",
    label: "£575,000",
  },
  {
    value: "600000",
    label: "£600,000",
  },
  {
    value: "625000",
    label: "£625,000",
  },
  {
    value: "650000",
    label: "£650,000",
  },
  {
    value: "675000",
    label: "£675,000",
  },
  {
    value: "700000",
    label: "£700,000",
  },
  {
    value: "725000",
    label: "£725,000",
  },
  {
    value: "750000",
    label: "£750,000",
  },
  {
    value: "775000",
    label: "£775,000",
  },
  {
    value: "800000",
    label: "£800,000",
  },
  {
    value: "825000",
    label: "£825,000",
  },
  {
    value: "850000",
    label: "£850,000",
  },
  {
    value: "875000",
    label: "£875,000",
  },
  {
    value: "900000",
    label: "£900,000",
  },
  {
    value: "1000000",
    label: "£1,000,000",
  },
  {
    value: "1100000",
    label: "£1,100,000",
  },
  {
    value: "1200000",
    label: "£1,200,000",
  },
  {
    value: "1300000",
    label: "£1,300,000",
  },
  {
    value: "1400000",
    label: "£1,400,000",
  },
  {
    value: "1500000",
    label: "£1,500,000",
  },
  {
    value: "1600000",
    label: "£1,600,000",
  },
  {
    value: "1700000",
    label: "£1,700,000",
  },
  {
    value: "1800000",
    label: "£1,800,000",
  },
  {
    value: "1900000",
    label: "£1,900,000",
  },
  {
    value: "2000000",
    label: "£2,000,000",
  },
  {
    value: "2250000",
    label: "£2,250,000",
  },
  {
    value: "2500000",
    label: "£2,500,000",
  },
  {
    value: "2750000",
    label: "£2,750,000",
  },
  {
    value: "3000000",
    label: "£3,000,000",
  },
  {
    value: "3250000",
    label: "£3,250,000",
  },
  {
    value: "3500000",
    label: "£3,500,000",
  },
  {
    value: "3750000",
    label: "£3,750,000",
  },
  {
    value: "4000000",
    label: "£4,000,000",
  },
  {
    value: "4250000",
    label: "£4,250,000",
  },
  {
    value: "4500000",
    label: "£4,500,000",
  },
]

const maxpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let maxPriceRange = pricemaxRange
  let maxpricedisable = ""
  if (currentRefinement.min !== undefined) {
    maxPriceRange = pricemaxRange.filter(
      x => parseInt(x.value) > parseInt(currentRefinement.min)
    )
  }
  if (currentRefinement.min == "10000000") {
    maxpricedisable = "disabled"
  }
  return (
    <Form.Group>
      <div className="select-box select-box-custom select-pricerange">
        <Select
          name="maxprice"
          options={maxPriceRange}
          defaultValue={currentRefinement.max || ""}
          placeholder="Max Price"
          classNamePrefix={"select-opt"}
          onChange={e => {
            maxpriceval = e.value
            if (e.value === "") {
              if (minpriceval) {
                refine({
                  min: minpriceval,
                })
              } else refine(e.value, e.label)
            } else {
              refine({
                min: minpriceval,
                max: e.value,
              })
            }
          }}
          isSearchable={false}
          styles={styles}
        />
      </div>
    </Form.Group>
  )
}

const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
// Max price

// Property Bedrooms
const bedroomSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let bedroomvalselect = ""
  if (currentRefinement.max !== undefined) {
    bedroomvalselect = 0
  } else if (currentRefinement.min !== undefined) {
    bedroomvalselect = currentRefinement.min
  }
  return (
    <>
      <Select
        name="bedroom"
        options={[
          { value: "", label: "Bedrooms" },
          { value: "1", label: "1+" },
          { value: "2", label: "2+" },
          { value: "3", label: "3+" },
          { value: "4", label: "4+" },
          { value: "5", label: "5+" },
        ]}
        placeholder="Bedrooms"
        classNamePrefix={"select-opt"}
        onChange={e => {
          if (e.value === "") {
            refine(e.value, e.label)
          } else if (e.value === "0") {
            refine({
              max: e.value,
            })
          } else {
            refine({
              min: e.value,
            })
          }
        }}
        isSearchable={false}
        styles={customStyles}
        components={{ DropdownIndicator: () => <DownIndicator /> }}
      />
    </>
  )
}

const CustombedroomSelectbox = connectRange(bedroomSelectbox)
// Property Bedrooms

// Property Type Filter
const propertyTypeFilter = ({ items, currentRefinement, refine, searchState, activeType }) => (
  <div className="property-radio-btn">
    <ul className="d-flex flex-wrap offer-list d-flex flex-wrap">
      <li>
        <label className="radio-box">
          <input
            type="radio"
            name="building"
            value=""

            onClick={event => {
              typefilter = null;
              Object.assign(searchState, { menu: { building: '' } })
              navigate(createURL(null, searchState))
              // refine(event.currentTarget.value)
            }}
          />{" "}
          <span className="radio-property-name">All Properties</span>
          <span className="radio-mark"></span>
        </label>
      </li>
      <li>
        <label className={`radio-box ${(activeType == "flat") ? 'checked' : ''}`}>
          <input
            type="radio"
            name="building"
            value="flat"
            onClick={event => {
              typefilter = "flat"
              Object.assign(searchState, { menu: { building: 'flat' } })
              navigate(createURL(null, searchState))
              // refine(event.currentTarget.value)
            }}
          />{" "}
          <span className="radio-property-name">Flat</span>
          <span className="radio-mark"></span>
        </label>
      </li>
      <li>
        <label className={`radio-box ${(activeType == "house-mid-terrace") ? 'checked' : ''}`}>
          <input
            type="radio"
            name="building"
            value="house-mid-terrace"
            onClick={event => {
              typefilter = "house-mid-terrace"
              Object.assign(searchState, { menu: { building: 'house-mid-terrace' } })
              navigate(createURL(null, searchState))
              // refine(event.currentTarget.value)
            }}
          />{" "}
          <span className="radio-property-name">House</span>
          <span className="radio-mark"></span>
        </label>
      </li>
      <li>
        <label className={`radio-box ${(activeType == "maisonette") ? 'checked' : ''}`}>
          <input
            type="radio"
            name="building"
            value="maisonette"
            onClick={event => {
              typefilter = "maisonette"
              Object.assign(searchState, { menu: { building: 'maisonette' } })
              navigate(createURL(null, searchState))
              // refine(event.currentTarget.value)
            }}
          />{" "}
          <span className="radio-property-name">Maisonette</span>
          <span className="radio-mark"></span>
        </label>
      </li>
      <li>
        <label className={`radio-box ${(activeType == "bungalow") ? 'checked' : ''}`}>
          <input
            type="radio"
            name="building"
            value="bungalow"
            onClick={event => {
              typefilter = "bungalow"
              Object.assign(searchState, { menu: { building: 'bungalow' } })
              navigate(createURL(null, searchState))
              // refine(event.currentTarget.value)
            }}
          />{" "}
          <span className="radio-property-name">Bungalow</span>
          <span className="radio-mark"></span>
        </label>
      </li>
    </ul>
  </div>
)

const CustomPropertyMenu = (propertyTypeFilter)
// const CustomPropertyMenu = connectMenu(propertyTypeFilter)
// Property Type Filter

// Sort by
const SortByPrice = ({ items, currentRefinement, refine }) => (
  <>
    {items.map((item, key) => {
      return item.label === "property_sortby_asc" ? (
        <Button
          data-tip
          data-for="registerTip"
          data-delay-show="300"
          variant="dark"
          className={item.class}
          onClick={event => {
            event.preventDefault()
            refine(item.value)
          }}
        >
          <i className="icon-filter"></i>
        </Button>
      ) : (
        <Button
          data-tip
          data-for="registerTip1"
          data-delay-show="300"
          variant="dark"
          className={item.class}
          onClick={event => {
            event.preventDefault()
            refine(item.value)
          }}
        >
          <i className="icon-filter"></i>
        </Button>
      )
    })}
  </>
)

const CustomSortBy = connectSortBy(SortByPrice)
// Sort by

// Maps
const MapResultsDevice = () => {
  return (
    <>
      <div id="map" className="map map-marker">
        <GoogleMapsLoader
          // apiKey={""}
          apiKey={process.env.GATSBY_MAPS_API_KEY}
        >
          {google => <MapResults1 google={google} />}
        </GoogleMapsLoader>
      </div>
    </>
  )
}
var InfoWindow
var onClickMarker
var html

const MapResults1 = props => {
  const InfoWindow = new props.google.maps.InfoWindow()

  const onClickMarker = ({ hit, marker }) => {
    console.log(hit)
    if (InfoWindow.getMap()) {
      InfoWindow.close()
      $(".icon-property_map_marker").removeClass("active")
    }
    $(".icon-property_map_marker").removeClass("active")
    marker.element.classList.add("active")
    var uriStr =
      hit.searchType === "sales" ? "property-for-sale/" : "property-to-rent/"
    const html =
      '<div class="popover-body p-0"> <div class="card p-1 border-0"><div class="card-body p-1"><a href="/' +
      uriStr +
      hit.slug +
      "-" +
      hit.objectID +
      '/"><figure><img class="property-img-map" src="' +
      (hit.images && hit.images.length > 0 ? hit.images[0]["425x300"] : "") +
      '" /><span class="card-tag"></span></figure></a><div class="info"><a href="/' +
      uriStr +
      hit.slug +
      "-" +
      hit.objectID +
      '/"><p class="mb-1">' +
      hit.title?.replace(/-/g, " ") +
      '</p></a><h4 class="mb-1 mt-1"><a href="/' +
      uriStr +
      hit.slug +
      "-" +
      hit.objectID +
      '/">' +
      (hit.price ? "£ " + hit?.price.toLocaleString() : "") +
      "</a></h4></div></div></div></div>"
    InfoWindow.setContent(html)
    InfoWindow.open(marker.getMap(), marker)
  }
  return (
    <>
      {/* {isMobile?
          <Configure
             hitsPerPage={1000}
          />
        :
        <Configure
             hitsPerPage={20}
          />
        } */}
      <GeoSearch
        google={props.google}
        enableRefineOnMapMove={false}
        initialZoom={10}
      >
        {({ hits }) => (
          <div>
            {hits.map(hit => (
              <>
                <CustomMarker
                  anchor={{ x: 0, y: -5 }}
                  key={hit.objectID}
                  position={hit._geoloc}
                  hit={hit}
                  className={`icon-property_map_marker map-marker-${hit.objectID}`}
                  onClick={({ marker }) => {
                    onClickMarker({ hit, marker })
                    // setactiveId(hit.objectID);
                  }}
                />
              </>
            ))}
          </div>
        )}
      </GeoSearch>
    </>
  )
}
// Maps

// Create URL
export const createURL = (props, state) => {
  let myarea = state.menu

  const isDefaultRoute =
    !state.query &&
    !state.SortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.bedroom

  if (isDefaultRoute) {
    return ""
  }

  var areaPath =
    "in-stoke-newington-and-hackney-and-north-london-and-east-london/"
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var propertypath = ""
  var sortPath = ""

  if (state.query) {
    if (state.query) {
      areaPath =
        "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }
  }

  const queryParameters = {}

  // Property path
  if (state.menu) {
    if (myarea["building"]) {
      propertypath = "type-" + myarea["building"] + "/"
    }
  }
  // Property path

  // Bedrooms & Price range path
  if (state.range) {
    // Bedrooms
    if (
      state.range.hasOwnProperty("bedroom") &&
      state.range.bedroom.max === 0
    ) {
      bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
    }
    if (state.range.bedroom) {
      if (state.range.bedroom.min) {
        bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
      }
    }

    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath =
          "between-" +
          state.range.price.min +
          "-and-" +
          state.range.price.max +
          "/" //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + "/" //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "over-" + state.range.price.min + "/" //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  // Bedrooms & Price range path

  // Sort path
  if (state.sortBy) {
    if (state.sortBy === index_name + "_price_asc") {
      sortPath = "sortby-price-asc/"
    }
    if (state.sortBy === index_name + "_price_desc") {
      sortPath = "sortby-price-desc/"
    }
    if (state.sortBy === index_name + "_most_recent") {
      sortPath = "sortby-most-recent/"
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })

  let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`

  // if ("/" === myUrl.substr(myUrl.length - 1))
  //   myUrl = myUrl.substr(0, myUrl.length - 1)

  return `/property/for-sale/${myUrl}`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
  if (location) {
    var query = ""
    let pathUri_main = location.pathname.split("/property/for-sale")

    var areaVal = ""
    var bedVal = 11
    var minpriceVal = ""
    var maxpriceVal = ""
    var pageVal = ""
    var propertyval = ""
    var sortVal = ""
    var sortVal_filt = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (pathUri[vi].indexOf("in-") >= 0) {
          areaVal = pathUri[vi].replace("in-", "")
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }

        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          if (sortVal_filt === "price-asc") {
            sortVal = index_name + "_price_asc"
          }

          if (sortVal_filt === "price-desc") {
            sortVal = index_name + "_price_desc"
          }

          if (sortVal_filt === "most-recent") {
            sortVal = index_name + "_most_recent"
          }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Property type
  if (propertyval) {
    // query += `&menu[building]=` + propertyval
  }

  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase()
    if (
      areaVal == "stoke-newington-and-hackney-and-north-london-and-east-london"
    )
      query += `&query=`
    else query += `&query=` + areaVal
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  // Bedrooms
  if (bedVal == 0) {
    query += `&range[bedroom][min]=` + 0
  } else if (bedVal < 10) {
    query += `&range[bedroom][min]=` + bedVal
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
}
// URL to searchstate

// Property search
const PropertySearch = () => {
  return (
    <div className="property-filter-search">
      <SearchBox />
    </div>
  )
}
// Property search

// Property price
const PropertyPrice = () => {
  return (
    <div className="property-price-filter">
      <div className="dropdown-exp d-lg-block">
        <CustomminpriceSelectbox attribute="price" />
        <CustommaxpriceSelectbox attribute="price" />
      </div>
    </div>
  )
}
// Property price

// Property type
const PropertyType = ({ searchState, activeType }) => {
  return (
    <div className="property-type-filter">
      <div className="dropdown-exp d-lg-block">
        <div className="mb-3">
          <h4>Property Type</h4>
          <CustomPropertyMenu attribute="building" searchState={searchState} activeType={activeType} />
        </div>
      </div>
    </div>
  )
}
// Property type

// Active filter onscroll
let lastScrollY = 0
let activeHeader = ""
// Active filter onscroll

class PropertySales extends React.Component {
  // constructor(props) {
  //     super(props);
  // }

  //
  state = {
    searchState: urlToSearchState(this.props.location),
    mapview: false,
    includeSoldinside: true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) })

      //
      sessionStorage.setItem("propertyPath", this?.props?.location?.pathname)
      //
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout(() => {
      navigate(searchStateToUrl(this.props, searchState), searchState)
    }, updateAfter)
    this.setState({ searchState })
  }

  onSearchStateChange2 = searchState => {
    this.setState({ searchState })
  }
  //

  // Property Status
  propertyStatus = e => {
    $(".property-search").removeClass("active-tab")
    $(".property-search-icon").removeClass("icon-minus-yellow")
    $(".property-filter-search").removeClass("active-filter")
    $(".property-price").removeClass("active-tab")
    $(".property-price-icon").removeClass("icon-minus-yellow")
    $(".property-price-filter").removeClass("active-filter")
    $(".property-filter").removeClass("active-tab")
    $(".property-filter-icon").removeClass("icon-minus-yellow")
    $(".property-type-filter").removeClass("active-filter")
  }
  // Property Status

  // Property search filter
  propertySearchFilter = e => {
    $(".property-search").toggleClass("active-tab")
    $(".property-search-icon").toggleClass("icon-minus-yellow")
    $(".property-filter-search").toggleClass("active-filter")

    $(".property-price").removeClass("active-tab")
    $(".property-price-icon").removeClass("icon-minus-yellow")
    $(".property-price-filter").removeClass("active-filter")
    $(".property-filter").removeClass("active-tab")
    $(".property-filter-icon").removeClass("icon-minus-yellow")
    $(".property-type-filter").removeClass("active-filter")
  }
  // Property search filter

  // Property price filter
  propertyPriceFilter = e => {
    $(".property-price").toggleClass("active-tab")
    $(".property-price-icon").toggleClass("icon-minus-yellow")
    $(".property-price-filter").toggleClass("active-filter")

    $(".property-filter").removeClass("active-tab")
    $(".property-filter-icon").removeClass("icon-minus-yellow")
    $(".property-type-filter").removeClass("active-filter")
    $(".property-search").removeClass("active-tab")
    $(".property-search-icon").removeClass("icon-minus-yellow")
    $(".property-filter-search").removeClass("active-filter")
  }
  // Property price filter

  // Property bedroom
  propertyBedroom = e => {
    $(".property-search").removeClass("active-tab")
    $(".property-search-icon").removeClass("icon-minus-yellow")
    $(".property-filter-search").removeClass("active-filter")
    $(".property-price").removeClass("active-tab")
    $(".property-price-icon").removeClass("icon-minus-yellow")
    $(".property-price-filter").removeClass("active-filter")
    $(".property-filter").removeClass("active-tab")
    $(".property-filter-icon").removeClass("icon-minus-yellow")
    $(".property-type-filter").removeClass("active-filter")
  }
  // Property bedroom

  // Property type filter
  propertyTypeFilterClick = e => {
    $(".property-filter").toggleClass("active-tab")
    $(".property-filter-icon").toggleClass("icon-minus-yellow")
    $(".property-type-filter").toggleClass("active-filter")

    $(".property-price").removeClass("active-tab")
    $(".property-price-icon").removeClass("icon-minus-yellow")
    $(".property-price-filter").removeClass("active-filter")
    $(".property-search").removeClass("active-tab")
    $(".property-search-icon").removeClass("icon-minus-yellow")
    $(".property-filter-search").removeClass("active-filter")
  }
  // Property type filter

  // Map & Grid
  showGrid = e => {
    $(".grid-view").removeClass("inactive-btn")
    $(".map-view").removeClass("active-btn")
    $(".left-side").removeClass("map-active")
    $(".right-side").removeClass("map-active")

    $(".grid-view").addClass("active-btn")
    $(".map-view").addClass("inactive-btn")
    $(".search-bottom-block").removeClass("mt-0")
  }

  showMap = e => {
    $(".map-view").removeClass("inactive-btn")
    $(".grid-view").removeClass("active-btn")
    $(".grid-view").addClass("inactive-btn")
    $(".map-view").addClass("active-btn")

    $(".left-side").addClass("map-active")
    $(".right-side").addClass("map-active")
    $(".search-bottom-block").addClass("mt-0")
  }

  showGridMobile = e => {
    $(".grid-view").removeClass("inactive-btn")
    $(".map-view").removeClass("active-btn")
    $(".left-side").removeClass("map-active")
    $(".right-side").removeClass("map-active")

    $(".grid-view").addClass("active-btn")
    $(".map-view").addClass("inactive-btn")
    $(".search-bottom-block").removeClass("mt-0")

    this.setState({ mapview: false })
  }

  showMapMobile = e => {
    $(".map-view").removeClass("inactive-btn")
    $(".grid-view").removeClass("active-btn")
    $(".grid-view").addClass("inactive-btn")
    $(".map-view").addClass("active-btn")

    $(".left-side").addClass("map-active")
    $(".right-side").addClass("map-active")
    $(".search-bottom-block").addClass("mt-0")

    this.setState({ mapview: true })
  }
  // Map & Grid

  // Responsive filter search
  filterSearchMobile = e => {
    $(".filter-bar").addClass("open-filterbar")
    let shareurl
    if (typeof window !== "undefined") {
      shareurl = window.location.href ? window.location.href : ""
    }
    sessionStorage.setItem("url", shareurl)
  }

  closeFilterSearch = e => {
    $(".filter-bar").removeClass("open-filterbar")
  }

  includeSoldclick = e => {
    this.setState({
      includeSoldinside: !this.state.includeSoldinside
    })
  }

  closeMenuFilter = () => {
    $(".filter-bar").removeClass("open-filterbar")

    if (sessionStorage.getItem("url") !== window.location.href) {
      // window.open(sessionStorage.getItem("url"), "_self")
      navigate(sessionStorage.getItem("url"))
    }
  }
  // Responsive filter search

  componentDidMount() {
    // load on top
    // $(document).ready(function(){
    //     $("html, body").scrollTop(0);
    // });
    // load on top

    // Filter active onscroll
    // window.addEventListener("scroll", this.handleScroll, true)
    // Filter active onscroll

    // Sortby
    $(".property_sortby_asc").click(function () {
      $(".property_sortby_asc").addClass("active")
      $(".property_sortby_desc").removeClass("active")
    })

    $(".property_sortby_desc").click(function () {
      $(".property_sortby_desc").addClass("active")
      $(".property_sortby_asc").removeClass("active")
    })
    // Sortby

    // Sold filter
    // $( ".filtersearchsold" ).insertAfter( ".defaultsoldfilter" );
    // Sold filter
    if (sessionStorage.getItem("searchindex") != null) {
      var idelement = "mySaleProp" + sessionStorage.getItem("searchindex")
      setTimeout(function () {
        var elmnt = document.getElementById(idelement.toString())
        if (elmnt) {
          elmnt.scrollIntoView()
          sessionStorage.removeItem("searchindex")
        }
      }, 1000)
    } else {
      // load on top
      $(document).ready(function () {
        $("html, body").scrollTop(0)
      })
      // load on top
    }

    // Click outside to close
    $(document).mouseup(function (e) {
      // Location dropdown
      var locationContainer = $(".property-search")

      if (
        !locationContainer.is(e.target) &&
        locationContainer.has(e.target).length === 0
      ) {
        $(".property-filter-search").removeClass("active-filter")
        $(".property-search").removeClass("active-tab")
        $(".property-search-icon").removeClass("icon-minus-yellow")
      }
      // Location dropdown

      // Price dropdown
      var priceContainer = $(".property-price")

      if (
        !priceContainer.is(e.target) &&
        priceContainer.has(e.target).length === 0
      ) {
        $(".property-price-filter").removeClass("active-filter")
        $(".property-price").removeClass("active-tab")
        $(".property-price-icon").removeClass("icon-minus-yellow")
      }
      // Price dropdown

      // Property type dropdown
      var propertyTypeContainer = $(".property-filter")

      if (
        !propertyTypeContainer.is(e.target) &&
        propertyTypeContainer.has(e.target).length === 0
      ) {
        $(".property-type-filter").removeClass("active-filter")
        $(".property-filter").removeClass("active-tab")
        $(".property-filter-icon").removeClass("icon-minus-yellow")
      }
      // Property type dropdown
    })
    // Click outside to close
  }

  // Filter active onscroll
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    lastScrollY = window.scrollY

    if (lastScrollY > 100) {
      activeHeader = "menu-scroll"
    } else if (lastScrollY < 100) {
      activeHeader = ""
    }

    this.setState({ activeHeader })
  }
  // Filter active onscroll

  // Sales & Rent dropdown
  onChangeSellRent = e => {
    navigate(`/${e.value}`)
  }
  // Sales & Rent dropdown


  getPropertyFilterList = (typefilter) => {
    let data = '';
    if (typefilter == "flat") {
      data = `AND (building:${typefilter})`
    } else if (typefilter == "maisonette" || typefilter == "bungalow") {
      data = `AND (style:${typefilter})`
    } else if (typefilter == "house-mid-terrace") {
      var houses = ["house-terraced", "house-detached", "Detached House"]
      data = 'AND (building:"' + (houses).join('" OR building:"') + '")'
    }
    return data;
  }

  render() {
    const searchParams = parseSearchUrl("sales", this.props.location.pathname)
    const h1 = propertyH1(searchParams)
    const areaName = searchParams.areas.replace(/-/g, " ")
    const pType = searchParams.pType.replace(/-/g, " ")

    const isPageLoadBg = typeof this.props.path === "undefined"
    const capitalizeSeconfLetter = s => {
      if (typeof s !== "string") return ""
      if (s.includes("+")) {
        return s
      } else {
        return s.charAt(1).toUpperCase() + s.slice(2)
      }
    }
    // Capitalize
    const capitalizeFirstLetter = s => {
      if (typeof s !== "string") return ""

      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const capitalize = data => {
      if (typeof data !== "string") return ""
      return data
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    }


    let statusdata = this.state.includeSoldinside ? ["for sale", "coa pending", "under offer"] : ["for sale", "coa pending"]

    let mystatus = ''
    if (statusdata) {
      if (Array.isArray(statusdata)) {
        mystatus = '(status:"' + (statusdata).join('" OR status:"') + '")'
      } else {
        mystatus = '(status:"' + (statusdata) + '")'
      }
    }

    let propertyTypeFilterList = '';

    if (typefilter) {
      propertyTypeFilterList = this.getPropertyFilterList(typefilter)
    } else if (searchParams.type) {
      propertyTypeFilterList = this.getPropertyFilterList(searchParams.type)
    }
    console.log(typefilter, 'typefilter')
    return (
      <React.Fragment>
        {!isPageLoadBg && (
          <SeoResults
            title={h1}
            searchParams={searchParams}
            location={this.props.location}
          />
        )}

        <div className={activeHeader + " header-search"}>
          <Header />
        </div>

        {/* <div className="d-none">
          {
            this?.props?.location?.pathname &&
            <LocationPath 
              propertyPath={this?.props?.location?.pathname}
            />
          }
        </div> */}
        {/* <div className="filtersearchsold">
                        <SoldFilter 
                            searchState={this.state.searchState}
                            searchClient={searchClient}
                            indexName={index_name}
                            createURL={createURL}
                            onSearchStateChange={this.onSearchStateChange2}
                        />
                    </div> */}
        <InstantSearch
          indexName={index_name}
          searchClient={searchClient}
          searchState={this.state.searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={createURL}
          routing="true"
        >
          <div className="d-none">
            {/* <RefinementList
              attribute="searchType"
              defaultRefinement={["sales"]}
            />
            <RefinementList attribute="publish" defaultRefinement={["true"]} />
            <RefinementList
              attribute="status"
              defaultRefinement={["for sale", "coa pending"]}
            /> */}
          </div>
          <Configure filters={`publish:true AND searchType:sales AND ${mystatus} ${propertyTypeFilterList}`} />
          <section className="search-banner for-sale-banner">
            <Container>
              <div className="search-banner-content">
                <h1 className="for-sale-content">
                  {/* {searchParams.areas_alias === "stoke-newington-and-hackney-and-north-london-and-east-london" &&
                  searchParams.bedrooms === "" &&
                  searchParams.type === "" &&
                  searchParams.price.min === "" &&
                  searchParams.price.max === ""
                    ? "Property for sale in Stoke Newington, Hackney, North and East London "
                    : h1} */}
                  {capitalizeSeconfLetter(h1)} (
                  <CustomStats />)
                </h1>
                <h4 className="property-content-sale">Beautiful Property</h4>
                {/* {capitalize(areaName.replace('%20',''))} */}
                {/* {JSON.stringify(searchParams)} */}
                <p>
                  Looking for{" "}
                  <span className="search-inline-span">
                    {searchParams ? h1 + "? " : ""}
                  </span>
                  {/* {searchParams.bedrooms ? "" : "a"}{" "}
                  {searchParams.bedrooms ? (
                    <span>
                      {searchParams.bedrooms > 0
                        ? searchParams.bedrooms + "+ bedroom"
                        : ""}
                    </span>
                  ) : (
                    ""
                  )}{" "} */}
                  {/* {searchParams?.type
                    ? searchParams?.type.replace(/-/g, " ")
                    : "home"} */}
                  {` We’re with you all the way. Our team of friendly, local `}
                  experts in{" "}
                  {areaName ===
                    "stoke newington and hackney and north london and east london"
                    ? "Stoke Newington, Hackney, Thetford and Norfolk"
                    : capitalize(areaName)}{" "}
                  are here to help you and to make good things happen!.
                </p>
              </div>
              <div className="button-search-box">
                <Button
                  variant="dark d-xl-none"
                  onClick={this.filterSearchMobile}
                >
                  <span className="d-md-none">Refine</span>
                  <span className="d-none d-md-block">Refine Search</span>
                  <i className="icon-plus-menu"></i>
                </Button>
                <ul className="d-none d-xl-block">
                  <li className={"dropdown_box"}>
                    <strong
                      className="custom-selectbox"
                      onClick={this.propertyStatus}
                    >
                      <span className="select-box-custom">
                        <Select
                          name="building"
                          options={[
                            {
                              value:
                                "property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london/",
                              label: "Buy",
                            },
                            {
                              value:
                                "property/to-rent/in-stoke-newington-and-hackney-and-north-london-and-east-london/",
                              label: "Rent",
                            },
                          ]}
                          placeholder="Buy"
                          classNamePrefix={"select-opt"}
                          onChange={this.onChangeSellRent}
                          isSearchable={false}
                          styles={customStyles}
                          components={{
                            DropdownIndicator: () => <DownIndicator />,
                          }}
                        />
                      </span>
                    </strong>
                  </li>
                  <li className={"dropdown_box property-search"}>
                    <strong onClick={this.propertySearchFilter}>
                      <span className="filter-text">{"Location"}</span>{" "}
                      <i
                        className={
                          "icon-plus-yellow property-search-icon filtering-icons"
                        }
                      ></i>
                    </strong>
                    <PropertySearch />
                  </li>
                  <li className={"dropdown_box property-price"}>
                    <strong onClick={this.propertyPriceFilter}>
                      <span className="filter-text">{"Price Range"}</span>{" "}
                      <i
                        className={
                          "icon-plus-yellow property-price-icon filtering-icons"
                        }
                      ></i>
                    </strong>
                    <PropertyPrice />
                  </li>
                  <li className={"dropdown_box"}>
                    <strong
                      className="custom-selectbox"
                      onClick={this.propertyBedroom}
                    >
                      <span className="select-box-custom select-box-bedroom">
                        <CustombedroomSelectbox attribute="bedroom" />
                      </span>
                    </strong>
                  </li>
                  <li className={"dropdown_box property-filter"}>
                    <strong onClick={this.propertyTypeFilterClick}>
                      <span className="filter-text">{"Type"}</span>{" "}
                      <i
                        className={
                          "icon-plus-yellow property-filter-icon filtering-icons"
                        }
                      ></i>
                    </strong>
                    <PropertyType searchState={this.state.searchState} activeType={searchParams.type} />
                  </li>
                </ul>
                <div className="banner-buttons">
                  {/* <CustomToggleRefinement
                    attribute="status"
                    label="Include Under Offer"
                    value={["under offer"]}
                    defaultRefinement={includeSold}
                  /> */}

                  <div className="switch-button ">
                    <label for="toggle-button">
                      <input
                        type="checkbox"
                        id="toggle-button"
                        onChange={this.includeSoldclick}
                        checked={this.state.includeSoldinside ? "checked" : ""}
                      />
                      <span className="toggle-switch">
                        <span className="toggle-switch-bar"></span>
                      </span>
                      <span className="toggle-text">Include Under Offer</span>
                    </label>
                  </div>


                  {/* <div className="filtersearchsold">
                                                <SoldFilter 
                                                    searchState={this.state.searchState}
                                                    searchClient={searchClient}
                                                    indexName={index_name}
                                                    createURL={createURL}
                                                    onSearchStateChange={this.onSearchStateChange2}
                                                />
                                            </div> */}
                  <div className="switch-button d-none">
                    {/* <ToggleRefinement
                                                    attribute="status"
                                                    label="Include Sold"
                                                    value={['under offer']}
                                                    defaultRefinement={['under offer']}
                                                /> */}
                    {/* <div className="d-none">
                                                    <ToggleRefinement
                                                        attribute="status"
                                                        label="under offer"
                                                        value={['under offer']}
                                                    />
                                                </div> */}
                  </div>
                  <button
                    type="button"
                    className="toggle-button d-xl-flex d-none"
                  >
                    <span
                      onClick={this.showGrid}
                      className={"active-btn toggle-icon grid-view"}
                    >
                      <i className="icon-grid"></i>
                    </span>
                    <span
                      onClick={this.showMap}
                      className={"inactive-btn toggle-icon map-view"}
                    >
                      <i className="icon-map"></i>
                    </span>
                  </button>
                  <button
                    type="button"
                    className="toggle-button d-xl-none d-flex"
                  >
                    <span
                      onClick={this.showGridMobile}
                      className={"active-btn toggle-icon grid-view"}
                    >
                      <i className="icon-grid"></i>
                    </span>
                    <span
                      onClick={this.showMapMobile}
                      className={"inactive-btn toggle-icon map-view"}
                    >
                      <i className="icon-map"></i>
                    </span>
                  </button>
                  <CustomSortBy
                    items={[
                      {
                        value: index_name + "_price_asc",
                        label: "property_sortby_asc",
                        class:
                          "button-filter filter-active-btn property_sortby_asc",
                      },
                      {
                        value: index_name,
                        label: "property_sortby_desc",
                        class: "button-filter property_sortby_desc active",
                      },
                    ]}
                    defaultRefinement={index_name}
                  />
                  {!isMobile ? (
                    <div>
                      <ReactTooltip
                        id="registerTip"
                        delayShow={300}
                        place="bottom"
                        effect="solid"
                        backgroundColor="#fff"
                        textColor="black"
                      >
                        Sort price low to high
                      </ReactTooltip>
                      <ReactTooltip
                        id="registerTip1"
                        delayShow={300}
                        place="bottom"
                        effect="solid"
                        backgroundColor="#fff"
                        textColor="black"
                      >
                        Sort price high to low
                      </ReactTooltip>
                    </div>
                  ) : null}
                </div>
              </div>
            </Container>

            <div className="filter-bar">
              <div className="btn-close-menu">
                <button
                  type="button"
                  className="close-btn"
                  onClick={this.closeMenuFilter}
                >
                  <i className="icon-close"></i>
                </button>
              </div>
              <div className="filter-items">
                <ul>
                  <li className={"dropdown_box"}>
                    <div className="filter-link">
                      <strong
                        className="custom-selectbox"
                        onClick={this.propertyStatus}
                      >
                        <span className="select-box-custom">
                          <Select
                            name="building"
                            options={[
                              {
                                value:
                                  "property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london/",
                                label: "Buy",
                              },
                              {
                                value:
                                  "property/to-rent/in-stoke-newington-and-hackney-and-north-london-and-east-london/",
                                label: "Rent",
                              },
                            ]}
                            placeholder="Buy"
                            classNamePrefix={"select-opt"}
                            onChange={this.onChangeSellRent}
                            isSearchable={false}
                            styles={customStyles}
                            components={{
                              DropdownIndicator: () => <DownIndicator />,
                            }}
                          />
                        </span>
                      </strong>
                    </div>
                  </li>
                  <li className={"dropdown_box property-search"}>
                    <div className="filter-link">
                      <strong
                        className="custom-selectbox"
                        onClick={this.propertySearchFilter}
                      >
                        <span className="custom-mobile-filter">
                          {"Location"}
                        </span>{" "}
                        <i
                          className={"icon-plus-yellow property-search-icon"}
                        ></i>
                      </strong>
                    </div>
                    <PropertySearch />
                  </li>
                  <li className={"dropdown_box property-price"}>
                    <div className="filter-link">
                      <strong
                        className="custom-selectbox"
                        onClick={this.propertyPriceFilter}
                      >
                        <span className="custom-mobile-filter">
                          {"Price Range"}
                        </span>{" "}
                        <i
                          className={"icon-plus-yellow property-price-icon"}
                        ></i>
                      </strong>
                    </div>
                    <PropertyPrice />
                  </li>
                  <li className={"dropdown_box"}>
                    <div className="filter-link">
                      <strong
                        className="custom-selectbox"
                        onClick={this.propertyBedroom}
                      >
                        <span className="select-box-custom select-box-bedroom">
                          <CustombedroomSelectbox attribute="bedroom" />
                        </span>
                      </strong>
                    </div>
                  </li>
                  <li className={"dropdown_box property-filter"}>
                    <div className="filter-link">
                      <strong
                        className="custom-selectbox"
                        onClick={this.propertyTypeFilterClick}
                      >
                        <span className="custom-mobile-filter">{"Type"}</span>{" "}
                        <i
                          className={"icon-plus-yellow property-filter-icon"}
                        ></i>
                      </strong>
                    </div>
                    <PropertyType searchState={this.state.searchState} />
                  </li>
                </ul>
                <div className="ipad-view-only">
                  {/* <CustomToggleRefinement
                    attribute="status"
                    label="Include Under Offer"
                    value={["under offer"]}
                    defaultRefinement={includeSold}
                  /> */}
                  <div className="switch-button ">
                    <label for="toggle-button">
                      <input
                        type="checkbox"
                        id="toggle-button"
                        onChange={this.includeSoldclick}
                        checked={this.state.includeSoldinside ? "checked" : ""}
                      />
                      <span className="toggle-switch">
                        <span className="toggle-switch-bar"></span>
                      </span>
                      <span className="toggle-text">Include Under Offer</span>
                    </label>
                  </div>

                </div>

                <div className="button-update">
                  <a
                    href="#"
                    onClick={this.closeFilterSearch}
                    className="btn btn-yellow"
                  >
                    Update
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="search-results full-width-results">
            <div className="d-flex justify-content-center">
              <div className="left-side">
                <Container>
                  <Row
                    id="property_list_results"
                    className="property_list_results"
                  >
                    <CustomInfiniteHits location={this.props.location} />
                    <CustomNoStats
                      location={this.props.location?.pathname}
                      status={"for sale"}
                      department={"residential"}
                    />
                  </Row>
                </Container>
              </div>
              <div className="right-side">
                {!isMobile && <MapResultsDesktop ptype="mySaleProp" />}
                {isMobile && this.state.mapview && (
                  <>
                    <Configure hitsPerPage={1000} />
                    <MapResultsDevice />
                  </>
                )}
              </div>
            </div>

            {/* Contact our team */}
            {!this.state.mapview && (
              <div className="container-custom">
                <div className="search-bottom-block">
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    className="search-bottom-image"
                    animateOnce={true}
                  >
                    <div>
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={VendorsSell}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={VendorsSell}
                        />
                        <img
                          src={VendorsSell}
                          alt="What's your home worth now? - Location Location"
                        />
                      </picture>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    className="search-bottom-content"
                    animateOnce={true}
                  >
                    <h3>What's your home worth now?</h3>
                    <p>
                      Book a meeting with one of our local property marketing
                      experts. All valuations are complimentary and provided
                      with no obligation on you to use our services. We’re good
                      like that.
                    </p>
                    <Link
                      className="btn btn-yellow sale-button"
                      to="/property-services/sell/property-valuation/"
                    >
                      Book a Valuation
                    </Link>
                  </ScrollAnimation>
                </div>
              </div>
            )}
            {/* Contact our team */}
          </section>
        </InstantSearch>
        <div className="search-footer">
          <Footer
            resultlabel="Sales"
            searchtype="sales"
            txt="sample"
            searchBedroomfield={searchParams.bedrooms}
            searchPtype={searchParams.type}
            Searcharea={searchParams}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default PropertySales
